import HttpRequest from './http-request'
import { getAuthToken } from '../utils/auth.utils'

class PrivateToursProvider extends HttpRequest {
  getItems (query) {
    this.setHeader(getAuthToken())
    return this.get('/private-tours', query)
  }

  getItemById (id) {
    this.setHeader(getAuthToken())
    return this.get(`/private-tours/${id}`)
  }

  deleteItemById (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/private-tours/${id}`)
  }
}

export default PrivateToursProvider
