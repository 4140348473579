<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        class="px-0">
        <v-row no-gutters>
          <v-col
            cols="12"
            md="auto">
            <v-btn
              color="primary"
              outlined
              depressed
              :ripple="false"
              style="width: fit-content; background-color: white;"
              @click="onBack()">
              <v-icon>
                mdi-chevron-left
              </v-icon>
              <span>
                ย้อนกลับ
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row class="form-container rounded-lg secondary--text">
          <v-col cols="12">
            <h3 class="primary--text">
              ข้อมูลการเดินทาง
            </h3>
            <v-divider class="mt-3" />
            <table style="border-spacing: 1rem; width: 100%;">
              <tr>
                <td
                  class="font-weight-bold"
                  width="200px">
                  วันเดินทาง:
                </td>
                <td>
                  {{ formData.startDate | dateFullFormat() }} - {{ formData.endDate | dateFullFormat() }}
                </td>
              </tr>
              <tr>
                <td
                  class="font-weight-bold"
                  width="200px">
                  ทวีป:
                </td>
                <td>
                  {{ formData.translate.continent }}
                </td>
              </tr>
              <tr>
                <td
                  class="font-weight-bold"
                  width="200px">
                  ประเทศ:
                </td>
                <td>
                  {{ formData.translate.countries.join(', ') }}
                </td>
              </tr>
              <!-- <tr>
                <td
                  class="font-weight-bold"
                  width="200px">
                  เมือง:
                </td>
                <td>
                  {{ formData.cities }}
                </td>
              </tr> -->
              <tr>
                <td
                  class="font-weight-bold"
                  width="200px">
                  จำนวนคน:
                </td>
                <td>
                  {{ formData.crews | showNumberFormat() }} คน
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <v-divider />
                </td>
              </tr>
              <tr>
                <td
                  class="font-weight-bold"
                  width="200px">
                  ที่พักที่ต้องการ:
                </td>
                <td>
                  {{ formData.accommodation.type }}
                </td>
              </tr>
              <tr>
                <td
                  class="font-weight-bold"
                  width="200px">
                  ระดับดาวที่พัก:
                </td>
                <td>
                  {{ formData.accommodation.star }} ดาว
                </td>
              </tr>
              <tr>
                <td
                  class="font-weight-bold"
                  width="200px">
                  ราคาที่ต้องการ/คน:
                </td>
                <td>
                  {{ formData.price | showNumberFormat() }} บาท
                </td>
              </tr>
              <tr>
                <td
                  class="font-weight-bold"
                  width="200px">
                  สถานที่ท่องเที่ยวที่สนใจ:
                </td>
                <td>
                  {{ formData.highlights || '-' }}
                </td>
              </tr>
              <tr>
                <td
                  class="font-weight-bold"
                  width="200px">
                  ความต้องการเพิ่มเติม:
                </td>
                <td>
                  {{ formData.note || '-' }}
                </td>
              </tr>
            </table>
          </v-col>
          <v-col cols="12">
            <h3 class="primary--text">
              ข้อมูลผู้ติดต่อ
            </h3>
            <v-divider class="mt-3" />
            <table style="border-spacing: 1rem; width: 100%;">
              <tr>
                <td
                  class="font-weight-bold"
                  width="200px">
                  ชื่อ - สกุล
                </td>
                <td>
                  {{ `${formData.firstName} ${formData.lastName}` }}
                </td>
              </tr>
              <tr>
                <td
                  class="font-weight-bold"
                  width="200px">
                  อีเมล
                </td>
                <td>
                  {{ formData.email }}
                </td>
              </tr>
              <tr>
                <td
                  class="font-weight-bold"
                  width="200px">
                  หมายเลขโทรศัพท์
                </td>
                <td>
                  {{ formData.tel }}
                </td>
              </tr>
            </table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PrivateToursProvider from '@/resources/private-tours.provider'

const PrivateToursService = new PrivateToursProvider()

export default {
  data: () => ({
    loading: false,
    formData: {
      startDate: '',
      endDate: '',
      continent: '',
      countries: [],
      cities: [],
      translate: {
        continent: '',
        countries: [],
        cities: []
      },
      crews: 1,
      accommodation: {
        type: '',
        star: 5
      },
      price: 0,
      highlights: [],
      note: '',
      firstName: '',
      lastName: '',
      tel: '',
      email: ''
    },
    tab: null,
    date: ''
  }),
  mounted () {
    this.getItemById()
  },
  methods: {
    onBack () {
      this.$router.push({ name: 'PrivateTourList' })
    },
    onEdit () {
      this.$router.push({
        name: 'PrivateTourEdit',
        params: {
          id: this.$route.params.id
        }
      })
    },
    async getItemById () {
      try {
        this.loading = true

        const { data } = await PrivateToursService.getItemById(this.$route.params.id)

        this.formData = { ...data }
      } catch (error) {
        console.error('getItemById', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.form-container {
  background-color: white;
  border: 1px solid #E0E0E0;
}
.menu-tabs {
  border: 1px solid #026EAA;
  border-radius: 8px;
  padding: 4px;
}
.active-tab {
  background-color: #026EAA;
  border-radius: 4px;
  color: white;
}
</style>
